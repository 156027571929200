
import Vue from 'vue'
import Vuex from 'vuex'
//import { state } from '../../../server/app/models/db';
import http from "../http-common";
const _PRICE_VERSION_ = '01.11.2024';

Vue.use(Vuex);
export default new Vuex.Store({
  state() {
    return {
      accounts: [],
      properties: [],
      productsGGG: [],
      catalog: [],
      tree:[],
      managers:[],
      //productsFlags: [],
      user_name: "",
      //manager_id: "",
      factory_list: "",
      sessionID: "",

      account_info: {},
      discount: 0,
      discount_sum: 0,
      total: 0,
      total_discount: 0,

      statusFlag: false,
      language: "rus",
      //isFetching: false,
      lock_keys: false,
      lock_draw_path: false,
      count_draw_path: 0,

      account_id: 0,
      //account_total: 0,
      //discount: 0,
      //discount_total: 0,
      //account_discount_total: 0,

    }
  },
  mutations: {

    SET_TREE_TO_STORE(state, tree) {
      state.tree = tree;
    },
    REFRESH_USER_DATA_IN_STORE( state ){
      state.user_name = localStorage.username;
      state.factory_list = localStorage.factorylist;
    },
    SET_USER_DATA_TO_STORE(state, response_data) {
      if ( response_data != null ){
        localStorage.username = response_data.username;
        //localStorage.manager_id = response_data.manager_id;
        localStorage.sessionID = response_data.sessionID;
        localStorage.factorylist = response_data.factorylist;
        state.user_name = response_data.username;
        //state.manager_id = response_data.manager_id;
        state.sessionID = response_data.sessionID;
        state.factory_list = response_data.factorylist;
      } else {
        delete localStorage.username;
        //delete localStorage.manager_id;
        delete localStorage.sessionID;
        delete localStorage.factorylist;
        state.user_name = "";
        //state.manager_id = "";
        state.sessionID = "";
        state.factory_list = "";  
      }
    },

    SET_ACCOUNTS_TO_STORE(state, accounts) {
      state.accounts = accounts;
    },

    SET_MANAGERS_TO_STORE(state, managers) {
      state.managers = managers;
    },

    SET_PROPERTIES_TO_STORE(state, properties) {
      state.properties = properties;
      //console.log(state.properties);
    },
    SET_PRODUCTS_TO_STORE(state, {products, catalog}) {
      products = products.map((product) => {
        return clearEmptyProductProperties(product);
      });
      for ( let i=0; i<products.length; i++ ){
        if ( products[i]['[sum]'] == undefined || products[i]['[sum]'] == '' ) products[i].err = true;
        if ( products[i].isEndGroup ) products[i].err = false;
      }
      state.productsGGG = products;
      catalog = catalog.map((catalog) => {
        return clearEmptyProductProperties(catalog);
      });
      state.catalog = catalog;
    },
    SET_PRODUCT_OPTIONS_TO_STORE(state, {options, idproducts}) {
      let indexOfProduct = state.productsGGG.findIndex((product) => product.idproducts == idproducts);
      state.productsGGG[indexOfProduct].options = options;
      state.productsGGG[indexOfProduct].change = false;
      //alert(state.productsGGG[indexOfProduct].options['[SPEC]'].length);
      //alert(indexOfProduct);
      //Vue.forceUpdate();
      //this.isFetching = false;
    },    
    SET_PRODUCT_OPTIONS_TO_STORE_BUNCH(state, products_options) {
      products_options.forEach((options_obj) => {
        state.productsGGG.find((product, index) => {
          if (product.idproducts == options_obj.idproducts) {
            state.productsGGG[index].options = options_obj.options;
            return true;
          }
        });
      })
    },
    SET_PRODUCT_PROPERTY_TO_STORE(state, {property_value, property_name, product_index}) {
      state.productsGGG[product_index][property_name] = property_value;
      state.productsGGG[product_index].change = true;
      let namepos = state.productsGGG[product_index].namepos;
      if ( namepos != undefined ){
        if ( namepos.indexOf("*") == -1 ) { namepos = "* " + namepos; state.productsGGG[product_index].namepos = namepos; }
      }
    },

    SET_CATALOG_PROPERTY_TO_STORE(state, {value, col, row}){
      value = value.replaceAll(";",",").replaceAll(":",".");
      switch(col){
        case 2: state.catalog[row-1]['[constrtype]'] = value; break;
        case 3: if ( !isNaN( value ) ){ state.catalog[row-1]['[num]'] = value; state.catalog[row-1]['[sum]'] = value * state.catalog[row-1]['[price]']; } break;
        case 4: if ( !isNaN( value ) ){ state.catalog[row-1]['[price]'] = value; state.catalog[row-1]['[sum]'] = state.catalog[row-1]['[num]'] * value; } break;
        case 6: if ( !isNaN( value ) ){ state.catalog[row-1]['[guillotine]'] = value; } break;
      }
    },

    DELETE_PRODUCT_FROM_STORE(state, idproducts) {


      let product_index = state.productsGGG.findIndex((product) => product.idproducts == idproducts);
      LeaveGroup( state.productsGGG,product_index );
      let products = JSON.parse(JSON.stringify(state.productsGGG)).filter((product) => product.idproducts != idproducts);
      if(state.productsGGG.at(-1).idproducts != idproducts) {
        for (let i = 0; i < products.length; i++) {
          products[i].idproducts = i + 1;
        } 
      }

      RefreshGroups( products );
      this.state.productsGGG = products;

      /*let total =*/ CalculateTotal( this.state /*products*/ );


    },
    DELETE_PROPERTY_VALUE_FROM_STORE(state, {product_index, property_name}) {
      delete state.productsGGG[product_index][property_name];
      state.productsGGG[product_index].change = true;
      RefreshGroups( state.productsGGG );
    },    
    DELETE_ACCOUNT_FROM_STORE(state, idaccounts) {

      let indexOfAccount = state.accounts.findIndex((account) => account.idaccounts == idaccounts);
      if (indexOfAccount != -1) {
        //delete state.accounts[indexOfAccount];
        state.accounts.splice(indexOfAccount,1);
      }

      /*let products = JSON.parse(JSON.stringify(state.productsGGG)).filter((product) => product.idproducts != idproducts);
      if(state.productsGGG.at(-1).idproducts != idproducts) {
       for (let i = 0; i < products.length; i++) {
        products[i].idproducts = i + 1;
       } 
      }
      state.productsGGG = products;*/
    },
    COPY_ACCOUNT_FROM_STORE(state, new_account) {
      //console.log("new_account:",new_account);
      state.accounts.push(new_account);
    },
    async ADD_NEW_PRODUCT_TO_STORE(state) {
      let l = state.productsGGG.length;
      let next_idproducts = l ? state.productsGGG.at(-1).idproducts + 1 : 1;
      let next_npos = l ? state.productsGGG.at(-1).npos + 1 : 1;
      let next_nnpos = 0, subgroup_flag = false;
      if ( l ){
        if ( state.productsGGG[l-1].isGroup ){
          next_npos = state.productsGGG[l-1].npos;
          next_nnpos = 1;
          subgroup_flag = true;
        }
        if ( state.productsGGG[l-1].isSubGroup ){
          next_npos = state.productsGGG[l-1].npos;
          next_nnpos = Number( state.productsGGG[l-1].nnpos ) + 1;
          subgroup_flag = true;
        }
      }
      let new_options = {};
      for( let i=0; i<state.properties.key.length; i++ ){
        new_options[state.properties.key[i]] = state.properties.val[i].split(";");     
      }
      let new_product = {
        '[factory]': 'Union', '[num]': '1',
        idproducts: next_idproducts,
        npos: next_npos,
        nnpos: next_nnpos,
        name: next_npos.toString(),
        options: new_options,
      };
      state.productsGGG.push(new_product);
      if ( subgroup_flag ){ // добавлена под группа
        JoinInGroup( state.productsGGG,next_idproducts-1,-1 );

        //new_product.isSubGroup = true;
        //new_product.name = "<=" + next_npos.toString() + ":" + next_nnpos.toString();
        //new_product["[elem_spec]"] = "под группа";
        //new_product.colcolor = state.productsGGG[l-1].colcolor;
        //new_product["[hv]"] = state.productsGGG[l-1]["[hv]"];

      }
      RefreshGroups( state.productsGGG );
    },


    async ADD_CATALOG_TO_STORE( state , node ) {
      //let l = state.catalog.length;
      //let next_idproducts = l ? state.productsGGG.at(-1).idproducts + 1 : 1;
      //let next_npos = l ? state.productsGGG.at(-1).npos + 1 : 1;
      //let next_nnpos = 0, subgroup_flag = false;
      let new_catalog = {
        '[factory]': 'Union', '[constr]': 'Catalog', '[num]': '1',
        //idproducts: next_idproducts,
        '[constrtype]': node.name,
        '[options1]': node.bigcode,
        '[price]': node.price,
        '[sum]': node.price,
        '[guillotine]': node.discount,        
        '[comment]': node.group,        
      };
      state.catalog.push( new_catalog );
    },


    COPY_PRODUCT_TO_STORE( /*dispatch,*/state , product_index) {
      const new_product = JSON.parse(JSON.stringify(state.productsGGG[product_index]));
      this.state.productsGGG.splice(product_index + 1, 0, new_product);
      let products = JSON.parse(JSON.stringify(state.productsGGG));
      for (let i = 0; i < products.length; i++) {
        products[i].idproducts = i + 1;
      }
      JoinInGroup( products,product_index + 1,-1 );
      RefreshGroups( products );
      this.state.productsGGG = products;
      CalculateTotal( this.state );
    },
    COPY_PRODUCT_TO_STORE2( /*dispatch,*/state , product_index) {
      const new_product = JSON.parse(JSON.stringify(state.productsGGG[product_index]));
      this.state.productsGGG.push(new_product);
      let products = JSON.parse(JSON.stringify(state.productsGGG));
      for (let i = 0; i < products.length; i++) {
        products[i].idproducts = i + 1;
      }
      JoinInGroup( products,products.length-1,-1 );
      RefreshGroups( products );
      this.state.productsGGG = products;
      CalculateTotal( this.state );
    },
    MOVE_PRODUCT_LEFT_IN_STORE(/*{*/state/*,commit}*/, product_index) {
      let products = JSON.parse(JSON.stringify(state.productsGGG));
      const new_idproducts = products[product_index - 1].idproducts;
      products[product_index - 1].idproducts = products[product_index].idproducts;
      products[product_index].idproducts = new_idproducts;
      //---------- слева группа, выходим из группы
      if ( products[product_index - 1].isGroup && products[product_index].isSubGroup ){
        LeaveGroup( products,product_index );
        //---------- слева другая группа, входим в группу
        if ( product_index - 2 >= 0 ){
          if ( products[product_index - 2].isGroup || products[product_index - 2].isSubGroup ){
            JoinInGroup( products,product_index,-2 );
          }
        }
      //---------- слева конец группы, входим в группу
      } else if ( products[product_index - 1].isEndGroup ){
        JoinInGroup( products,product_index,-1 );
      //---------- перемещаем конец группы, продукт слева выходит из группы, если он под группа
      } else if ( products[product_index].isEndGroup && products[product_index-1].isSubGroup ){
        LeaveGroup( products,product_index-1 );
      //---------- перемещаем конец группы, продукт слева суммирует ширину, если он группа
      } else if ( products[product_index].isEndGroup && products[product_index-1].isGroup ){
        let i = Number( product_index ) + 1, lv = 0;
        while ( i < products.length && !products[i].isGroup && !products[i].isEndGroup ){
          let lvsub = Number( products[i]['[lv]'] );
          if ( !isNaN( lvsub ) ) lv = lv + lvsub;
          i ++;
        }
        products[product_index-1]['[lv]'] = lv;
      }
      products = products.sort((a, b) => a.idproducts - b.idproducts);
      RefreshGroups( products );
      state.productsGGG = products;
    },
    MOVE_PRODUCT_RIGHT_IN_STORE(state, product_index) {
      let products = JSON.parse(JSON.stringify(state.productsGGG));
      const new_idproducts = products[product_index + 1].idproducts;
      products[product_index + 1].idproducts = products[product_index].idproducts;
      products[product_index].idproducts = new_idproducts;
      //---------- продукт справа группа, входим в группу
      if ( products[product_index + 1].isGroup ){
        //---------- продукт является подгруппой, сначала выходим из группы
        if ( products[product_index].isSubGroup ){
          LeaveGroup( products,product_index );
        }
        if ( products[product_index].isEndGroup ){
          products[product_index + 1]['[lv]'] = "0";
        }
        JoinInGroup( products,product_index,1 );
      //---------- продукт справа конец группы, выходим из группы
      } else if ( products[product_index + 1].isEndGroup ){
        LeaveGroup( products,product_index );
      //---------- перемещаем конец группы, продукт справа входит в группу
      } else if ( products[product_index].isEndGroup ){
        JoinInGroup( products,product_index+1,-1 );
      }
      products = products.sort((a, b) => a.idproducts - b.idproducts);
      RefreshGroups( products );
      state.productsGGG = products;
    },


    MOVE_PRODUCT_UP_IN_STORE(state, product_index) {
      let cat = JSON.parse(JSON.stringify(state.catalog));
      let cat_elem = cat[product_index - 1];
      cat[product_index - 1] = cat[product_index];
      cat[product_index] = cat_elem;
      state.catalog = cat;
    },
    MOVE_PRODUCT_DOWN_IN_STORE(state, product_index) {
      let cat = JSON.parse(JSON.stringify(state.catalog));
      let cat_elem = cat[product_index + 1];
      cat[product_index + 1] = cat[product_index];
      cat[product_index] = cat_elem;
      state.catalog = cat;
    },
    DELETE_CATALOG_FROM_STORE( state, product_index ){
      state.catalog.splice( product_index,1 );
      CalculateTotal( this.state );
    },
    COPY_CATALOG_TO_STORE( state, product_index ) {
      const new_product = JSON.parse( JSON.stringify( state.catalog[product_index] ) );
      this.state.catalog.splice( product_index + 1, 0, new_product );
      CalculateTotal( this.state );
    },
    COPY_CATALOG_TO_STORE2( state, product_index ) {
      const new_product = JSON.parse( JSON.stringify( state.catalog[product_index] ) );
      this.state.catalog.push( new_product );
      CalculateTotal( this.state );
    },

    ADD_NEW_ACCOUNT_TO_STORE(state, new_account) {
      //console.log("new_account:",new_account);
      state.accounts.push(new_account);
    },

    /*SET_NEW_ACCOUNT_INDEX(state,index){
      state.new_idaccount = index;
    },*/

    UPDATE_ACCOUNT_IN_STORE(state, object) { // idaccounts, account, customer

      let indexOfAccount = state.accounts.findIndex((acc) => acc.idaccounts == object.account_info.account_id);
      if (indexOfAccount != -1) {
        state.accounts[indexOfAccount].date1 = DateStringToString( object.account_info.date1 );
        state.accounts[indexOfAccount].deal = object.account_info.deal;
        state.accounts[indexOfAccount].account = object.account_info.account;
        state.accounts[indexOfAccount].kp = object.account_info.kp;
        state.accounts[indexOfAccount].customer = object.account_info.customer;
        //state.accounts[indexOfAccount].manager_id = object.account_info.manager_id;
        state.accounts[indexOfAccount].client_id = object.account_info.client_id;

        state.accounts[indexOfAccount].factory = object.account_info.factory;
        if ( state.productsGGG.length > 0 ){
          state.accounts[indexOfAccount].factory = state.productsGGG[0]['[factory]'];
          object.account_info.factory = state.productsGGG[0]['[factory]'];
        }

        state.accounts[indexOfAccount].total = object.account_info.total;
        state.accounts[indexOfAccount].discount = object.account_info.discount;
        state.accounts[indexOfAccount].total_discount = object.account_info.total_discount;
        state.accounts[indexOfAccount].comment = object.account_info.comment;
        state.accounts[indexOfAccount].extendinfo = object.account_info.extendinfo;
        state.accounts[indexOfAccount].printinfo = object.account_info.printinfo;
        state.accounts[indexOfAccount].locked = object.account_info.locked;
        state.account_info.locked = object.account_info.locked;
        //console.log("state.accounts[indexOfAccount]=account",object);
      }

    },
    SET_ACCOUNT_INFO_TO_STORE(state, account_info/*account_id*/) {
      //console.log("new_account:",new_account);
      state.account_id = account_info.account_id;
      state.discount = account_info.discount;
    },
    STOP_ANIMATION_IN_STORE( state ){
      state.statusFlag = false;
      let statusElement = document.getElementsByClassName("c-buttons-row--container-after");
      if ( statusElement != undefined ){
        if ( statusElement.length == 1 ){
          statusElement[0].style.left = "0%";
          statusElement[0].style.width = "100%";
        }
      }
    },
    UPDATE_ACCOUNT_STATE_IN_STORE( state, info ) { // idaccounts, account, customer
      let indexOfAccount = state.accounts.findIndex((acc) => acc.idaccounts == info.idaccounts); if ( indexOfAccount == -1 ) return;
      switch( info.mode ){
        case "lock":    state.accounts[indexOfAccount].locked = info.data.locked; break;
        case "archive": state.accounts[indexOfAccount].archive = info.data.archive; break;
      }
    },

  },
  actions: {
    DOWNLOAD_DATA(/*{commit}*/){
      http.put("download", { sessionID:localStorage.sessionID, mode:0 } ).then(response => {
        if ( response.data.status == 'ok' ){
          alert( "Данные успешно загружены для пользователей: " + response.data.user );
        } else {
          alert("Ошибка загрузки данных.");
        }
      }).catch(e => { console.log(e); });

    },
    SET_ID(/*{commit}*/){
    http.put("download", { sessionID:localStorage.sessionID, mode:1 } ).then(response => {
      if ( response.data.status == 'ok' ){
        alert( "Данные успешно загружены для пользователей: " + response.data.user );
      } else {
        alert("Ошибка загрузки данных.");
      }
    }).catch(e => { console.log(e); });

    },

    REFRESH_USER_DATA({commit}){
      commit("REFRESH_USER_DATA_IN_STORE");
    },
    UPDATE_ACCOUNT({commit}, { account_info }){
      commit("UPDATE_ACCOUNT_IN_STORE", { account_info });
    },
    async FETCH_ACCOUNTS({ commit }, { thisobj,mode,filter }) {
      if ( mode == 1 ) this.state.accounts.length = 0;
      //if ( this.state.accounts.length ) return;
      http.get("accounts", {params: {sessionID: localStorage.sessionID, vmode: thisobj.vmode, filter:filter } }).then(response => {
        //console.log("DATA:",response.data);
        //if ( response.data.length == 0 ) { goLogin( thisobj ); return; }
        if ( response.data.length == 1 && response.data[0] == "no login" ) { goLogin( thisobj ); return; }
        commit("SET_ACCOUNTS_TO_STORE", response.data);
        thisobj.cursor_pos.max = response.data.length-1;
        if ( response.data.length > 0 ) thisobj.account_user_name = response.data[0].username; else thisobj.account_user_name = "";
      }).catch(e => { console.log(e); });
    },


    FETCH_TREE({ commit }, {thisobj, mode, num_node, filter}) {
      //if ( this.state.tree.length ) return;
      http.get("tree", {params: {sessionID: localStorage.sessionID, mode:mode, num_node:num_node, filter:filter } }).then(response => {
        //console.log("TREE DATA:",thisobj,response.data );
        //if (response.data.length == 0) { goLogin( thisobj ); return; }
        commit("SET_TREE_TO_STORE", response.data);
        thisobj.tree_cursor_pos.col_max = 5;
        thisobj.tree_cursor_pos.row_max = this.state.tree.length;
        for ( let i=0; i<thisobj.tree.length; i++ ){
          if ( thisobj.tree[i].code == num_node ){
            if ( i+2<=thisobj.tree_cursor_pos.row_max ) thisobj.tree_cursor_pos.row = i+2;
            thisobj.$nextTick(function(){ this.modeSet("tree"); });
            break;
          }
        }
        //console.log("num_node:",num_node);
        //alert(this.state.tree.length);
      }).catch(e => { console.log(e); });
    },
    ADD_CATALOG({ commit }, {thisobj, node}){
      commit("ADD_CATALOG_TO_STORE", node );
      thisobj.cat_cursor_pos.col_max = 6;
      thisobj.cat_cursor_pos.row_max = this.state.catalog.length;
      thisobj.cat_cursor_pos.LastRow();
      CalculateTotal( this.state );
    },
    FETCH_PROPERTIES({ commit }, {thisobj, factory_name} ) {
      if (this.state.properties.length) return;
      else {
        //alert ( "FETCH_PROPERTIES: "+factory_name );
        http.get("properties", {params: { sessionID: localStorage.sessionID, factory_name:factory_name } }).then(response => {
          if (response.data.length == 0) { goLogin( thisobj ); return; }
          commit("SET_PROPERTIES_TO_STORE", response.data);
        }).catch(e => { console.log(e); });
      }
    },
    FETCH_PRODUCTS({ commit }, {/*thisobj,*/ idaccounts} ) {
      return http.get("products", { params: { sessionID: localStorage.sessionID, idaccounts: idaccounts }}).then(async response => {
        //---------- эту проверку нельзя восстанавливать, потому что будет сбой при входе в пустой счет
        //if (response.data.length == 0) { goLogin( thisobj ); return; }
        //console.log(response.data.length);
        commit("SET_PRODUCTS_TO_STORE", { products:response.data.products, catalog:response.data.catalog} );
      }).catch(e => { console.log(e); });
    },
    async FETCH_PRODUCT_OPTIONS({state, commit}, { thisobj, changed_property_name, product_index /*, base_flag*/ }) {
      let product = {...state.productsGGG[product_index]};
      let product_obj = {idproducts: product.idproducts};

      delete product.idproducts;
      delete product.options;
      product = clearEmptyProductProperties(product);
      product_obj.values = {...product};
      product_obj.changed_property_name = changed_property_name;
      state.lock_keys = true;
      return http.get("products-options", { params: {sessionID: localStorage.sessionID, product_obj: product_obj /*, base_flag:base_flag*/ }} ).then(response => {
        //console.log( "http.get products-options" );
        state.lock_keys = false;
        if (response.data.idproducts == undefined) { goLogin( thisobj ); return; }

        let num_dis = false, l_dis = false, h_dis = false, lv_dis = false, hv_dis = false, tracklen2_dis = false/*, group_type_dis = false*/;
        for (let key in response.data.options) {
          if ( key == '[num]' && response.data.options[key] == '[dis]' ) num_dis = true;
          if ( key == '[l]' && response.data.options[key] == '[dis]' ) l_dis = true;
          if ( key == '[h]' && response.data.options[key] == '[dis]' ) h_dis = true;
          if ( key == '[lv]' && response.data.options[key] == '[dis]' ) lv_dis = true;
          if ( key == '[hv]' && response.data.options[key] == '[dis]' ) hv_dis = true;
          if ( key == '[tracklen2]' && response.data.options[key] == '[dis]' ) tracklen2_dis = true;
          //if ( key == '[group_type]' && response.data.options[key] == '[serv]' ) group_type_dis = true;
          if ( response.data.options[key] == '[hid]' ) state.productsGGG[product_index][key] = ""; // принудительная очистка значения
        }
        if ( response.data.options['[INFO]'] != undefined ){
          if ( num_dis ){ //---------- добавлено недавно для корректного пересчета номинальных размером, если вводится размер проема
            let num = response.data.options['[INFO]']['[num]'];
            if ( num != undefined ){
              if ( isNaN( num ) ) num = "1";
              state.productsGGG[product_index]['[num]'] = num;
            }
          }
          if ( l_dis ){
            let l = response.data.options['[INFO]']['[l]'];
            if ( l != undefined ){
              if ( isNaN( l ) ) l = "";
              state.productsGGG[product_index]['[l]'] = l;
            }
          }
          if ( lv_dis ){
            let lv = response.data.options['[INFO]']['[lv]'];
            if ( lv != undefined ){
              if ( isNaN( lv ) ) lv = "";
              state.productsGGG[product_index]['[lv]'] = lv;
            }
          }
          if ( h_dis ){ //---------- добавлено недавно для корректного пересчета номинальных размером, если вводится размер проема
            let h = response.data.options['[INFO]']['[h]'];
            if ( h != undefined ){
              if ( isNaN( h ) ) h = "";
              state.productsGGG[product_index]['[h]'] = h;
            }
          }
          if ( hv_dis ){
            let hv = response.data.options['[INFO]']['[hv]'];
            if ( hv != undefined ){
              if ( isNaN( hv ) ) hv = "";
              state.productsGGG[product_index]['[hv]'] = hv;
            }
          }
          if ( tracklen2_dis ){
            let tracklen2 = response.data.options['[INFO]']['[tracklen2]'];
            if ( tracklen2 != undefined ){
              if ( isNaN( tracklen2 ) ) tracklen2 = "";
              state.productsGGG[product_index]['[tracklen2]'] = tracklen2;
            }
          }
          /*if ( group_type_dis ){
            let group_type = response.data.options['[INFO]']['[group_type]'];
            if ( group_type != undefined ){
              //if ( isNaN( tracklen2 ) ) tracklen2 = "";
              state.productsGGG[product_index]['[group_type]'] = group_type;
            }
          }*/
          //console.log( response.data.options['[INFO]']['[tracklen2]'] );
        }
        commit("SET_PRODUCT_OPTIONS_TO_STORE", {options: response.data.options, idproducts: response.data.idproducts});
      }).catch(e => { console.log(e); });
    },
    async FETCH_PRODUCT_OPTIONS_BUNCH({ dispatch,state }, { thisobj, products } ) {
      let /*base_flag,*/ total = 0, sum = 0;
      state.account_info.total = total;
      for ( let i=0; i<products.length; i++ ){
        //base_flag = 0;                                // ничего не делать
        //if ( i==0 ) base_flag += 1;                   // открыть
        //if ( i==products.length-1 ) base_flag += 2;   // закрыть
        await dispatch( 'FETCH_PRODUCT_OPTIONS', { thisobj:thisobj, changed_property_name:"", product_index:i /*, base_flag:base_flag*/ });
        if ( !products[i].isGroup && !products[i].isEndGroup ){
          sum = products[i]['[sum]'];
          if ( sum != undefined && !isNaN( sum ) ){
            total = total + Number( sum );
            state.discount = thisobj.account_info.discount;
            state.discount_sum = discount_total_calc( total, thisobj.account_info.discount );
            state.total = String( Intl.NumberFormat().format( total ) );
            state.total_discount = account_discount_total_calc( total, thisobj.account_info.discount );
      
            //state.account_info.discount_sum = discount_total_calc( total, state.account_info.discount );
            //state.account_info.total = String( Intl.NumberFormat().format( total ) );
            //state.account_info.total_discount = account_discount_total_calc( total, state.account_info.discount );
          }
        }
      }
      /*return http.get("products-options/bunch", { params: { sessionID: localStorage.sessionID, products: products }} ).then(response => {
        if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
        commit("SET_PRODUCT_OPTIONS_TO_STORE_BUNCH", response.data);
      }).catch(e => { console.log(e); });*/
    },

    async RECALCULATE_TOTAL({ dispatch }, { thisobj } ) { // пересчитать ВСЕ блоки
      for ( let i = 0; i < this.state.productsGGG.length; i++ ){
        this.state.productsGGG[i].change = true;
      }
      await dispatch( 'FETCH_PRODUCT_OPTIONS_CHANGED', { thisobj:thisobj });
    },

    /*CLEAR_CHANGE_FLAG() { // пересчитать ВСЕ блоки
      for ( let i = 0; i < this.state.productsGGG.length; i++ ){
        this.state.productsGGG[i].change = false;
      }
    },*/



    async FETCH_PRODUCT_OPTIONS_CHANGED({ dispatch }, { thisobj } ) { // найти и пересчитать измененные блоки
      //---------- пересчитать блок
      for ( let i = 0; i < this.state.productsGGG.length; i++ ){
        if ( this.state.productsGGG[i].change ){
          await dispatch( 'FETCH_PRODUCT_OPTIONS', { thisobj:thisobj, changed_property_name:"", product_index:i /*, base_flag:3*/ });
          await dispatch( 'CALCULATE_PRODUCT', i );
          this.state.productsGGG[i].change = false;
          console.log("FETCH_PRODUCT_OPTIONS_CHANGED: ",i);
        }
      }
      //---------- расчитать суммы групп
      let group_index = -1, sum = 0, constrgroup_num = 0, /*group_type = "",*/ group_constr = "";
      for ( let i = 0; i < this.state.productsGGG.length; i++ ){
        if ( this.state.productsGGG[i].isGroup ){
          if ( group_index != -1 ){
            this.state.productsGGG[group_index]['[price]'] = sum;
            this.state.productsGGG[group_index]['[sum]'] = sum;
            this.state.productsGGG[group_index]['[constrgroup_num]'] = constrgroup_num;
            group_constr = this.state.productsGGG[group_index]['[constr]'];
            //this.state.productsGGG[group_index]['[group_type]'] = group_type;
            this.state.productsGGG[group_index].options['[SPEC]'][0] = {index:0, name:group_constr, num:1, price:sum}
            //console.log("group_constr",group_constr);
          }
          group_index = i; sum = 0; constrgroup_num = 0; //group_type = "";
        } else if ( this.state.productsGGG[i].isSubGroup ){
          sum = sum + Number( this.state.productsGGG[i]['[sum]'] );
          //group_type = this.state.productsGGG[i]['[group_type]'];
          //console.log("group_type",group_type);
          constrgroup_num ++;
        } else if ( this.state.productsGGG[i].isEndGroup ){
          if ( group_index != -1 ){
            this.state.productsGGG[group_index]['[price]'] = sum;
            this.state.productsGGG[group_index]['[sum]'] = sum;
            this.state.productsGGG[group_index]['[constrgroup_num]'] = constrgroup_num;            
            //this.state.productsGGG[group_index]['[group_type]'] = group_type;
            //console.log("SET group_type",group_type);
            group_constr = this.state.productsGGG[group_index]['[constr]'];
            this.state.productsGGG[group_index].options['[SPEC]'][0] = {index:0, name:group_constr, num:1, price:sum}
            //console.log("group_constr",group_constr);
            group_index = -1; sum = 0; constrgroup_num = 0; //group_type = "";
          }
        }
        if ( i == this.state.productsGGG.length-1 && group_index != -1 ){
          this.state.productsGGG[group_index]['[price]'] = sum;
          this.state.productsGGG[group_index]['[sum]'] = sum;
          this.state.productsGGG[group_index]['[constrgroup_num]'] = constrgroup_num;            
          group_constr = this.state.productsGGG[group_index]['[constr]'];
          //this.state.productsGGG[group_index]['[group_type]'] = group_type;
          //console.log("SET group_type",group_type);

          this.state.productsGGG[group_index].options['[SPEC]'][0] = {index:0, name:group_constr, num:1, price:sum}
          //console.log("group_constr",group_constr);
          group_index = -1; sum = 0; constrgroup_num = 0; //group_type = "";
        }
      }
      RefreshGroups( this.state.productsGGG );
    },
    SET_PRODUCT_PROPERTY({state,commit}, {property_value, property_name, product_index}) {
      commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value, property_name, product_index});
      RebuildGroup( {state,commit}, {property_value, property_name, product_index} );
    },

    SET_CATALOG_PROPERTY({state,commit}, { value, col, row }){
      commit("SET_CATALOG_PROPERTY_TO_STORE", {value, col, row});
      CalculateTotal( state );
    },

    CALCULATE_PRODUCT( /*{ dispatch,*/{state} /*}*/, index ){
      let prod = state.productsGGG[index].options['[SPEC]'];
      if ( prod != undefined ){
        let price = 0, price_flag = true;
        for ( let i=0; i < prod.length; i++ ) {
          if ( prod[i].price == null || prod[i].num == null ) { price_flag = false; break; }
          if ( Number.isNaN( prod[i].price ) || Number.isNaN( prod[i].num ) ) { price_flag = false; break; }
          if ( prod[i].price == 0 || prod[i].num == 0 ) { price_flag = false; break; }
          price = price + Math.round( prod[i].price * prod[i].num );
        }
        if ( state.productsGGG[index].isGroup ) price = Number( state.productsGGG[index]['[price]'] );
        const num = Number( state.productsGGG[index]['[num]'] );
        if ( num == null || Number.isNaN( num ) || typeof( num ) != "number" || num == 0 ) price_flag = false;
        if ( price == null || Number.isNaN( price ) || typeof( price ) != "number" || price == 0 ) price_flag = false;
        if ( price_flag ){
          state.productsGGG[index]['[price]'] = String( price );
          state.productsGGG[index]['[sum]'] = String( price*num );
          state.productsGGG[index].err = false;
        } else {
          state.productsGGG[index]['[price]'] = "";
          state.productsGGG[index]['[sum]'] = "";
          state.productsGGG[index].err = true;
          if ( state.productsGGG[index].isEndGroup ) state.productsGGG[index].err = false;
        }
        state.productsGGG[index]['[pver]'] = _PRICE_VERSION_;
      }

      /*let account_total =*/ CalculateTotal( state /*state.productsGGG*/ );
      //this.state.account_total = account_total;
      //this.state.discount_total = discount_total_calc( account_total, this.state.discount );
      //this.state.account_discount_total = account_discount_total_calc( account_total, this.state.discount );

      //this.calculateResultSumm();
    },

    CALCULATE_TOTAL( { state } ){

      /*let account_total =*/ CalculateTotal( state /*state.productsGGG*/ );
      //state.account_total = account_total;
      //state.discount_total = discount_total_calc( account_total, state.discount );
      //state.account_discount_total = account_discount_total_calc( account_total, state.discount );

    /*  let sum = 0.0;
      for ( let i = 0; i < state.productsGGG.length; i ++ ){
        if ( !state.productsGGG[i].isGroup && !state.productsGGG[i].isEndGroup ){
          let val = state.productsGGG[i]['[sum]'];
          if ( val != undefined ) sum = sum + Number( val );
        }
      }
      sum = String( Intl.NumberFormat().format( sum ) );
      state.account_total = sum; 
      //this.account_info.total = sum;*/
    },


    DELETE_PRODUCT({commit}, idproducts) {
      commit("DELETE_PRODUCT_FROM_STORE", idproducts);
    },
    DELETE_PROPERTY_VALUE({commit}, {product_index, property_name}) {
      commit("DELETE_PROPERTY_VALUE_FROM_STORE", {product_index, property_name});      
    },
    //UPDATE_ACCOUNT_PRODUCTS({state,commit}, {idaccounts, account, customer}) {
    UPDATE_ACCOUNT_PRODUCTS({state,commit}, { account_info }) {
      let products = [], catalog = [];
      if(state.productsGGG.length) {
        /*let*/ products = [...state.productsGGG].map((product) => {
          product = {...product};
          delete product.options;
          delete product.namepos;
          delete product.npos;
          product = clearEmptyProductProperties(product);
          return product;
        });
      }
      catalog = state.catalog;

      account_info.discount = state.discount;
      account_info.discount_sum = state.discount_sum;
      account_info.total = state.total;
      account_info.total_discount = state.total_discount;
        
      http.put("products", { sessionID:localStorage.sessionID, products:products, catalog:catalog, account_info:account_info } ).then(response => {
        //commit("`UPDATE_ACCOUNT_IN_STORE`", {idaccounts, account, customer});
        commit("UPDATE_ACCOUNT_IN_STORE", { account_info });
        console.log(`Products of account ${response.data.idaccounts} was succesfully updated to database!`);
      }).catch(e => { console.log(e); });
      /*} else {
        console.error("Cannot update products, there is no products in store!")
      }*/
    },
    START_ANIMATION({state}) {

      let statusPos = 0;
      state.statusFlag = true; 
      setTimeout( function run() {
        if ( state.statusFlag ){
          let statusElement = document.getElementsByClassName("c-buttons-row--container-after");
          if ( statusElement != undefined ){
            if ( statusElement.length == 1 ){
              statusPos = statusPos + 5; if ( statusPos >= 100 ) statusPos = 0;
              statusElement[0].style.left = statusPos + "%";
              statusElement[0].style.width = "5%";
            }
          }
          setTimeout( run, 100 );
        }
      }, 100 );

    },
    STOP_ANIMATION({commit}) {
      commit("STOP_ANIMATION_IN_STORE");
    },

    SET_PICTURE({state}, {thisobj, col, row, val}) {
      let fn = state.properties.pictures[row-1], key = state.properties.key[row-1], tr = true;
      if ( fn != "" ){
        if ( val != "" ) fn = fn.replaceAll( key,val );
        while( tr ){
          let key = getUnknownKey( fn ); if ( key == "" ) break;
          let val = state.productsGGG[col-1][key];
          if ( val == undefined ) val = "{" + key.replace("[","").replace("]","") + "}";
          fn = fn.replaceAll( key,val );
        }
        //console.log( fn );
        const divImgView = document.getElementById( "divImgView" );
        if ( divImgView != undefined ){
          state.lock_keys = true;
          http.get("picturepath", {params: {sessionID: localStorage.sessionID, fn:fn} }).then(response => {
            state.lock_keys = false;
            if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
            if (response.data.nameweb != undefined) divImgView.children[0].src = response.data.nameweb;
          }).catch(e => { console.log(e); });
         }       
      } else {
        const divImgView = document.getElementById( "divImgView" );
        if ( divImgView != undefined ) divImgView.children[0].src = "https://config1.ru/UNIONdm/empty.png";
        //if ( divImgView != undefined ) divImgView.children[0].src = "../assets/empty.png";
      }
    },

    async SET_DRAW({state}, {thisobj, col/*, row, val*/}) {
      const divDrawView = document.getElementById( "divDrawView" );
      if ( divDrawView != undefined ){
        if ( state.productsGGG[col-1].isGroup || state.productsGGG[col-1].isSubGroup || state.productsGGG[col-1].isEndGroup ){
          let i = -1;
          if ( state.productsGGG[col-1].isGroup ) i = col-1;
          if ( state.productsGGG[col-1].isSubGroup || state.productsGGG[col-1].isEndGroup ) i = GetGroupIndex( state.productsGGG,col-1 );
          if ( i == -1 ) return;
          let group_products = [];
          let product = clearEmptyProductProperties( state.productsGGG[i] );
          delete product.options; group_products.push( product ); i ++;
          while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
            product = clearEmptyProductProperties( state.productsGGG[i] )
            let info = product.options['[INFO]'], draw_path = undefined;
            if ( info != undefined ) draw_path = product.options['[INFO]']['[draw_path]'];
            product['[draw_path]'] = draw_path;
            //console.log(draw_path);
            delete product.options; group_products.push( product ); i ++;
          }

          state.lock_keys = true;
          if ( state.lock_draw_path ){ return; }
          state.lock_draw_path = true;
          state.count_draw_path ++;
          //console.log("drawpath call...",state.count_draw_path);

          let err_flag = false;
          for ( i=0; i<group_products.length; i++ ){

            http.get("drawpath", {params: {sessionID: localStorage.sessionID, product:group_products[i], product_index:state.productsGGG[col-1].nnpos, pos:i, len:group_products.length } }).then(response => {
              state.lock_draw_path = false;
              state.lock_keys = false;
              if ( response.data.length == 0 ) { thisobj.$router.push("/login"); return; }
              //alert(response.data.nameweb);
              if  ( response.data.nameweb != undefined ){
                if ( response.data.nameweb == 'wait' ) { return; }
                if ( response.data.nameweb == 'error' ) { err_flag = true; return; }
                divDrawView.children[0].src = response.data.nameweb[0];
                if ( response.data.nameweb.length > 1 ){
                  divDrawView.children[0].style.width = "45%";
                  divDrawView.children[1].style.width = "45%";
                  divDrawView.children[1].src = response.data.nameweb[1];
                  divDrawView.children[1].style.display = ""; //  visibility = "visible";
                } else {
                  divDrawView.children[0].style.width = "100%";
                  divDrawView.children[1].style.width = "0";
                }
                //alert(response.data.nameweb);
              }
            }).catch(e => { state.lock_keys = false; console.log(e); });

            if ( err_flag ){ alert("Error"); break; }
          }
          
        } else {
          divDrawView.children[0].src = "https://config1.ru/UNIONdm/empty.png";
          divDrawView.children[0].style.width = "100%";
          divDrawView.children[1].style.width = "0";
          //divDrawView.children[0].src = "../assets/empty.png";
        }
      }
    },

    PRINT_ACCOUNT_PRODUCTS({state, commit}, { account_info, mode }) {

      if( state.productsGGG.length || state.catalog.length ) {
        let products = [...state.productsGGG].map((product) => {
          product = {...product};

          let draw_path = product.options['[INFO]']['[draw_path]'];
          product['[draw_path]'] = draw_path;
          delete product.options;

          product = clearEmptyProductProperties(product);
          return product;
        });

        let catalogs = [...state.catalog].map((catalog) => {
          catalog = {...catalog};
          delete catalog.idproducts;
          return catalog;
        });

        account_info.discount = state.discount;
        account_info.discount_sum = state.discount_sum;
        account_info.total = state.total;
        account_info.total_discount = state.total_discount;

        //console.log(`Products of account ${account_info.account_id} has start printed!`)
        //http.put("print", { sessionID:localStorage.sessionID, idaccounts:idaccounts, products:products, account:account, customer:customer }).then(response => { // "print/${idaccounts}"
        http.put("print", { sessionID:localStorage.sessionID, products:products, catalogs:catalogs, account_info:account_info, mode:mode }).then(response => { // "print/${idaccounts}"

          //window.open( "/output" );
          //window.open("http://localhost:8081/../../output/dimon.html");
          //thisobj.$router.push("/output");

          //var str = Object.entries(response.data.file);


          //alert( "Данные: " + response.data.file );
          //window.open(response.data.file, '_blank');


          //console.log(`Products of account ${account_info.account_id} has finish printed!`)
    
          commit("STOP_ANIMATION_IN_STORE");

          if ( response.data.fpath != null ) {



            /*let fso = CreateObject("Scripting.FileSystemObject");  
            let s = fso.CreateTextFile("C:\test.txt", True);
            s.writeline("HI");
            s.writeline("Bye");
            s.writeline("-----------------------------");
            s.Close();*/




            /*const a = document.createElement('a');
            let url = response.data.fpath; 
            a.href = url;
            a.download = url.split('/').pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);*/
            return;
          }

          var win = window.open("", "КП " + account_info.kp );
          win.onload = function(){
            win.document.body.contentEditable = 'true';
            win.document.designMode = 'on';
          }






          /*, "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840)*/
          win.document.body.innerHTML = response.data.file.replace( "<title></title>","<title>КП " + account_info.kp + " от " + DateStringToString( account_info.date1 ) + "</title>" );
          let p = 1, offset1 = 950, /*offset2 = 900,*/ offset3 = 950, last_el, page_height = 930;

          //---------- разрыв страницы для основной спецификации
          let els = win.document.getElementsByName( "ReportLine" );
          if ( els.length > 1 ){
            els.forEach( async ( el ) => {
              if ( el.getBoundingClientRect().top > p*offset1 ){
                p ++;
                let el0 = el.parentElement;
                let el2 = document.createElement('div');
                el2.style = "page-break-after:always;"
                el0.insertBefore( el2,el );
                //---------- тут конечно надо как то нормально сделать при наличии времени
                let el3 = el2.nextSibling; //console.log( el3 );
                let el4 = el3.firstChild.nextSibling; //console.log( el4 );
                let el5 = el4.childNodes; //console.log( el5 );
                el5[1].style = el5[1].getAttribute("style") + "; border-top: 1px solid black;";
                el5[5].style = el5[5].getAttribute("style") + "; border-top: 1px solid black;";
                //---------- тут конечно надо как то нормально сделать при наличии времени
                if ( page_height == 0 ){
                  page_height = el.getBoundingClientRect().top;
                  console.log( "page_height1",page_height );
                }
              }
              last_el = el;
            });
          }

          //---------- разрыв страницы для ручного подбора
          els = win.document.getElementsByName( "CatalogLine" );
          els.forEach( async ( el ) => {
            //console.log( el.getBoundingClientRect() );
            if ( el.getBoundingClientRect().top > p*offset1 ){
              p ++;
              let el0 = el.parentElement;
              let el2 = document.createElement('div');
              el2.style = "page-break-after:always;"
              el0.insertBefore( el2,el );
              //---------- тут конечно надо как то нормально сделать при наличии времени
              let el3 = el2.nextSibling; //console.log( el3 );
              let el4 = el3.firstChild.nextSibling; //console.log( el4 );
              let el5 = el4.childNodes; //console.log( el5 );
              el5[1].style = el5[1].getAttribute("style") + "; border-top: 1px solid black;";
              el5[3].style = el5[3].getAttribute("style") + "; border-top: 1px solid black;";
              el5[5].style = el5[5].getAttribute("style") + "; border-top: 1px solid black;";
              el5[7].style = el5[7].getAttribute("style") + "; border-top: 1px solid black;";
              el5[9].style = el5[9].getAttribute("style") + "; border-top: 1px solid black;";
              //---------- тут конечно надо как то нормально сделать при наличии времени
              if ( page_height == 0 ){
                page_height = el.getBoundingClientRect().top;
                console.log( "page_height2",page_height );
              }
            }
          });

          //---------- разрыв страницы для ???
          els = win.document.getElementsByClassName( "EndTable1" );
          if ( els != undefined && els.length > 0 ){
            if ( last_el != undefined ){
              if ( els[0].getBoundingClientRect().top > p*offset1 ){
                p ++;
                let el0 = last_el.parentElement;
                let el2 = document.createElement('div');
                el2.style = "page-break-after:always;"
                el0.insertBefore( el2,last_el );
                //---------- тут конечно надо как то нормально сделать при наличии времени
                let el3 = el2.nextSibling; //console.log( el3 );
                let el4 = el3.firstChild.nextSibling; //console.log( el4 );
                let el5 = el4.childNodes; //console.log( el5 );
                el5[1].style = el5[1].getAttribute("style") + "; border-top: 1px solid black;";
                el5[3].style = el5[3].getAttribute("style") + "; border-top: 1px solid black;";
                //---------- тут конечно надо как то нормально сделать при наличии времени
                if ( page_height == 0 ){
                  page_height = last_el.getBoundingClientRect().top;
                  console.log( "page_height3",page_height );
                }
              }
            }
          }

          /*els = win.document.getElementsByClassName( "FooterLine1" );
          els[0].style = "position: absolute; left:0; top:" + page_height*p*1.05 + "px;";
          els = win.document.getElementsByClassName( "FooterLine1" );
          console.log( els[0].getBoundingClientRect().top );*/

          /*els = win.document.getElementsByClassName( "FooterLine1" );
          if ( els != undefined && els.length > 0 ){
            if( els[0].getBoundingClientRect().top < p*offset2 ){
              console.log( "p:",p,els[0].getBoundingClientRect().top,p*offset2,p*offset2-els[0].getBoundingClientRect().top );
              let el0 = els[0].parentElement;
              let el2 = document.createElement('tr');
              let hg = Math.round( p*offset2-els[0].getBoundingClientRect().top ); //if ( i == 0 ) hg = hg - 14;
              el2.style = "height:" + hg.toString() + "px;";
              el0.insertBefore( el2,els[0] );
            }
          }*/

          //p = 1;
          /*els = win.document.getElementsByClassName( "FooterLine1" );
          if ( els != undefined ){
            for ( let i=0; i<els.length; i++ ){
              while( els[i].getBoundingClientRect().top > p*offset2 ){ p ++; }
              if( els[i].getBoundingClientRect().top < p*offset2 ){
                let el0 = els[i].parentElement;
                let el2 = document.createElement('tr');
                let hg = Math.round( p*offset2-els[i].getBoundingClientRect().top ); if ( i == 0 ) hg = hg - 14;
                el2.style = "height:" + hg.toString() + "px;";
                el0.insertBefore( el2,els[i] );
              }
            }
          }*/

          p = 1;
          els = win.document.getElementsByClassName( "FooterLine2" );
          if ( els != undefined ){
            for ( let i=0; i<els.length; i++ ){
              while( els[i].getBoundingClientRect().top > p*offset3 ){ p ++; }
              if( els[i].getBoundingClientRect().top < p*offset3 ){
                let el0 = els[i].parentElement;
                let el2 = document.createElement('tr');
                let hg = Math.round( p*offset3-els[i].getBoundingClientRect().top ); if ( i == 0 ) hg = hg - 14;
                el2.style = "height:" + hg.toString() + "px;";
                el0.insertBefore( el2,els[i] );
              }
            }
          }


          
          let el = win.document.getElementById("TableReportGroup2");
          let el0 = el.parentElement;
          let el2 = document.createElement('div'); el2.style = "page-break-after:always;"
          el0.insertBefore( el2,el );

          //---------- разрыв страницы для спецификации фрамуг и панелей
          /*setTimeout( function() {
            p = 1; last_el = null;
            let start_el = win.document.getElementById("StartReportGroup2");
            let start_el_top = start_el.getBoundingClientRect().top;
            console.log("readyState, start_el_top:",win.document.readyState,start_el_top);

            els = win.document.getElementsByName( "ExtReportLine" );
            if ( els.length > 1 ){
              els.forEach( async ( el ) => {
                if ( el.getBoundingClientRect().top > start_el_top + p*offset1 ){
                  p ++;
                  let el0 = el.parentElement;


                  let el2 = document.createElement('div');
                  el2.style = "page-break-after:always;"
                  el0.insertBefore( el2,el );
                  //el0.insertBefore( el2,el );
                  //---------- тут конечно надо как то нормально сделать при наличии времени
                  //let el3 = el2.nextSibling; //console.log( el3 );
                  //let el4 = el3.firstChild.nextSibling; //console.log( el4 );
                  //let el5 = el4.childNodes; //console.log( el5 );
                  //el5[1].style = el5[1].getAttribute("style") + "; border-top: 1px solid black;";
                  //el5[3].style = el5[3].getAttribute("style") + "; border-top: 1px solid black;";
                  //---------- тут конечно надо как то нормально сделать при наличии времени

                  let el6 = win.document.getElementById("TableReportGroup2").cloneNode(true);
                  el0.insertBefore( el6,el );

                  //if ( page_height == 0 ){
                  //  page_height = el.getBoundingClientRect().top;
                  //  //console.log( "page_height1",page_height );
                  //}
                }
                last_el = el;
              });
            }
          
          }, 1000 );*/

          //let routeData = thisobj.$router.resolve(response.data.file);
          //({name: response.data.file, query: {data: response.data.file}});
          //window.open(routeData.href, '_blank');


          //thisobj.$router.push( response.data.file );
          //console.log(`Products of account ${response.data.idaccounts} was succesfully printed!`)

          //thisobj.$router.push(response.data.file);

          //http://localhost:3000/download/book.png




        }).catch(e => { console.log(e); });
      } else {
        commit("STOP_ANIMATION_IN_STORE");
        alert("Нет данных для формирования КП.\nСоздайте хотя бы одну конструкцию, нажав клавишу INS.");
        console.error("Cannot print products, there is no products in store!");
      }
    },

    SET_ACCOUNT_STATE({/*state,*/ commit}, { thisobj, idaccounts, mode, e }) {
      http.put("accounts-state",{ params: { sessionID: localStorage.sessionID, idaccounts: idaccounts, mode: mode } } ).then(response => {
        if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
        //account_info.locked = response.data.locked;

        if ( response.data.status == "error" ){ alert( response.data.text ); return; }
        switch( mode ){
          case "lock":
            if ( response.data.locked == '1' ) {
              thisobj.locked = true; if ( !e.shiftKey ) alert("Счет заблокирован.");
            } else {
              thisobj.locked = false; if ( !e.shiftKey ) alert("Счет разблокирован.");
            }
            break;
          case "archive":
            if ( response.data.archive == '1' ) {
              thisobj.archive = true; if ( !e.shiftKey ) alert("Счет заархивирован.");
            } else {
              thisobj.archive = false; if ( !e.shiftKey ) alert("Счет разархивирован.");
            }
            break;
        }
        commit("UPDATE_ACCOUNT_STATE_IN_STORE", { idaccounts:idaccounts, mode:mode, data:response.data });
        //thisobj.$forceUpdate();
        
        thisobj.AccountSetLock();
        //thisobj.forceRerender5();
      }).catch(e => { console.log(e); });
    },

    async ADD_NEW_PRODUCT({commit}) {
      //this.isFetching = false;
      commit("ADD_NEW_PRODUCT_TO_STORE"); 
    },
    COPY_PRODUCT({commit}, product_index) {
      commit("COPY_PRODUCT_TO_STORE", product_index);
    },
    COPY_PRODUCT2({commit}, product_index) {
      commit("COPY_PRODUCT_TO_STORE2", product_index);
    },
    MOVE_PRODUCT_LEFT({commit}, product_index) {
      commit("MOVE_PRODUCT_LEFT_IN_STORE",product_index);
      //ReloadChangedProducts( state,dispatch );
    },    
    MOVE_PRODUCT_RIGHT({commit}, product_index) {
      commit("MOVE_PRODUCT_RIGHT_IN_STORE",product_index);
    },
    MOVE_PRODUCT_UP({commit}, product_index){
      commit("MOVE_PRODUCT_UP_IN_STORE",product_index);
    },
    MOVE_PRODUCT_DOWN({commit}, product_index){
      commit("MOVE_PRODUCT_DOWN_IN_STORE",product_index);
    },
    DELETE_CATALOG({commit}, product_index){
      commit("DELETE_CATALOG_FROM_STORE",product_index);
    },
    COPY_CATALOG({commit}, product_index){
      commit("COPY_CATALOG_TO_STORE",product_index);
    },
    COPY_CATALOG2({commit}, product_index){
      commit("COPY_CATALOG_TO_STORE2",product_index);
    },

    DELETE_ACCOUNT({commit}, idaccounts) {
      //alert("DELETE_ACCOUNT: " +localStorage.username+"  "+ idaccounts); ///${idaccounts}
      http.put("accounts-del",{ params: { sessionID: localStorage.sessionID, idaccounts: idaccounts } } ).then(response => {
        //if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
        commit("DELETE_ACCOUNT_FROM_STORE", response.data.idaccounts);
      }).catch(e => { console.log(e); });
    },
    COPY_ACCOUNT({commit}, idaccounts) {
      http.put("accounts-copy",{ params: { sessionID: localStorage.sessionID, idaccounts: idaccounts } } ).then(response => {
        //if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
        commit("COPY_ACCOUNT_FROM_STORE", response.data);
      }).catch(e => { console.log(e); });
    },
    SET_ACCOUNT_INFO({commit}, account_info/*account_id*/) {
      commit("SET_ACCOUNT_INFO_TO_STORE", account_info/*account_id*/);
    },
    CHECK_USER({commit}, {thisobj, username, password}) {
      http.put(`login`, {username:username, password:password, sessionID: localStorage.sessionID, commit}).then(response => {
        if (response.data.OK){
          console.log(`User ${username} was succesfully login!`);
          commit("SET_USER_DATA_TO_STORE", response.data);

          this.state.accounts.length = 0;
          this.state.properties.length = 0;
          this.state.productsGGG.length = 0;
          localStorage.filter = '[{"id":"mainbase","lastname":null,"firstname":null}]';

          thisobj.$router.push("/records");
          //alert(thisobj);
          //this.router.push("/records");
        } else {
          console.log(`User ${username} was error login!`);
          commit("SET_USER_DATA_TO_STORE", null);
          //console.log(thisobj.name); // замена чтобы не делать переход
        }
      }).catch(e => { console.log(e); });
    },
    async ADD_NEW_ACCOUNT( {commit},{ thisobj, factory }) {
      http.get("accounts-new",{ params: { sessionID: localStorage.sessionID, factory:factory } } ).then(response => {
        //if (response.data.length == 0) { thisobj.$router.push("/login"); return; }
        //console.log("ADD_NEW_ACCOUNT response.data: ",response.data);

        commit("ADD_NEW_ACCOUNT_TO_STORE", response.data);
        //commit("SET_NEW_ACCOUNT_INDEX", response.data.idaccounts);
        thisobj.cursor_pos.max = thisobj.cursor_pos.max + 1;
        thisobj.new_idaccount = response.data.idaccounts;


        /*setTimeout( function() {
          let index = thisobj.accounts.findIndex((account) => account.idaccounts == new_idaccount);
          thisobj.cursor_pos.max = thisobj.cursor_pos.max + 1;
          thisobj.setCursorRow( index );
        }, 1000 );*/
  

        /*thisobj.$nextTick(function(){ 
        });*/

        //thisobj.$forceUpdate();



      }).catch(e => { console.log(e); });
    },
    /*async*/ //GET_FACTORY_LIST( {commit}, resolve ) {
      /*http.get("factorylist", {params: {sessionID: localStorage.sessionID} }).then(response => {
        commit("SAVE_FACTORY_LIST_TO_STORE", response.data );
        resolve( response.data );
      }).catch(e => { console.log(e); });*/
      //resolve( this.state.factory_list );
    //},
    /*async*/ SEND_MAIL( {commit} /*, resolve*/ ) {
      http.get("sendmail", {params: {sessionID: localStorage.sessionID} }).then(response => {
        commit("STOP_ANIMATION_IN_STORE");
        //resolve( response.data );
        console.log( response.data );
      }).catch(e => { console.log(e); });
      //resolve( this.state.factory_list );
    },
    SEND_TO_KIS({state,commit}, { thisobj, account_info }) {
      if( state.productsGGG.length ) {
        let products = [...state.productsGGG].map((product) => {
          product = {...product};
          delete product.options;
          product = clearEmptyProductProperties(product);
          return product;
        });
        let flag_exist = false;
        if ( account_info.account != "" && account_info.client_id != "" ) flag_exist = true;
        http.put("sendtokis", { sessionID:localStorage.sessionID, products:products, account_info:account_info } ).then(response => {
          //commit("UPDATE_ACCOUNT_IN_STORE", { account_info });
          commit("STOP_ANIMATION_IN_STORE");

          //response = { data: { status: "ok", response: { bill_num: "21485", client: { client_id: "000204862", }, }, } }; // тестирование передачи

          if ( response.data.status == "ok" ){
            account_info.account = response.data.response.bill_num;
            account_info.client_id = response.data.response.client.client_id;
            thisobj.forceRerender4();

            if ( flag_exist ){
              alert(`Данные успешно переданы в КИС.\nВ сделке ${account_info.deal} обновлен счет ${response.data.response.bill_num}.`);
              console.log(`Данные успешно переданы в КИС.\nВ сделке ${account_info.deal} обновлен счет ${response.data.response.bill_num}.`);
            } else {
              alert(`Данные успешно переданы в КИС.\nВ сделке ${account_info.deal} создан новый счет ${response.data.response.bill_num}.`);
              console.log(`Данные успешно переданы в КИС.\nВ сделке ${account_info.deal} создан новый счет ${response.data.response.bill_num}.`);
            }
            commit("UPDATE_ACCOUNT_IN_STORE", { account_info });
          } else if ( response.data.status == "error" && response.data.text != undefined ){
            alert( response.data.text );
          } else {
            alert("Ошибка передачи счета в КИС.\nОбратитесь к системному администратору.");
            console.log("Cannot send products, ERROR: ", response.data.error);
          }
          //alert( response.data.status );
        }).catch(e => {
          commit("STOP_ANIMATION_IN_STORE");
          alert("Ошибка передачи счета в КИС.\nОбратитесь к системному администратору.");
          console.log("Cannot send products, ERROR: ", e); 
        });
      } else {
        commit("STOP_ANIMATION_IN_STORE");
        console.error("Cannot send products, there is no products in store!")
      }
    },
    SET_LANGUAGE( {state}, lang ){
      state.language = lang;
    },
    async GET_LANGUAGE( {state}, resolve ){
      resolve( state.language );
    },

    CHANGE_DISCOUNT( {state},discount ){
      state.discount = discount;
      CalculateTotal( this.state );
      //state.discount_sum = discount_total_calc( state.account_info.total, discount );
      //state.total = state.account_info.total;
      //state.total_discount = account_discount_total_calc( state.account_info.total, discount );
    },
    
    async FETCH_MANAGERS( {commit}/*, resolve, thisobj*/ ){
      http.get("managers", {params: {sessionID: localStorage.sessionID } }).then(response => {
        //console.log("DATA:",response.data);
        //if ( response.data.length == 0 ) { goLogin( thisobj ); return; }
        commit("SET_MANAGERS_TO_STORE", response.data);
        //alert( response.data[0].lastname );
        //resolve( response.data );
      }).catch(e => { console.log(e); });
    },

  },
  methods: {
  },
  getters: {
    GET_ACCOUNT_ID(state) {
      return state.account_id;
    },
  },
  setters: {
  },
})


function clearEmptyProductProperties(product) {
  return Object.fromEntries(Object.entries(product).filter((property) => property[1]));
}

function getUnknownKey( s ){
  let i1 = s.indexOf("["); let i2 = s.indexOf("]");
  if (i1!=-1 && i2!=-1 && i1<i2) return s.substring(i1,i2+1); else return "";
}

function GetGroupIndex( products,subIndex ){ // поиск индекса группы слева
  let i = Number( subIndex );
  while ( i > 0 && !products[i].isGroup ) i --;
  if ( !products[i].isGroup ) return -1; else return i;
}

function GetGroupIndexRight( products,subIndex ){ // поиск индекса группы справа
  let i = Number( subIndex );
  while ( i < products.length-1 && !products[i].isGroup ) i ++;
  if ( !products[i].isGroup ) return -1; else return i;
}

function RebuildGroup( {state,commit}, {property_value, property_name, product_index} ){ // перестроить группу
  
  let i;
  let isGroup = state.productsGGG[product_index].isGroup;
  let isSubGroup = state.productsGGG[product_index].isSubGroup;
  if ( !isGroup && !isSubGroup ) return;

  //---------- в группе изменилась ширина - пройти по всем членам группы и разогнать по ним ширину
  if ( isGroup && property_name == "[lv]" ){
    let i = Number( product_index ) + 1;
    let lv = Number( property_value ), lvsub0 = 0;
    while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
      let lvsub = Number( state.productsGGG[i][property_name] );
      if ( !lvsub ) lvsub0 ++; else lv = lv - lvsub;
      i ++;
    }
    if ( lvsub0 ) { // есть пустые ширины, разогнать по ним
      lv = lv / lvsub0;
      i = Number( product_index ) + 1;
      while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
        let lvsub = Number(state.productsGGG[i][property_name]);
        if ( !lvsub ) { commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:lv, property_name:property_name, product_index:i}); }
        i ++;
      }
    } else { // нет пустых ширин, изменить первую ширину группы
      i = Number( product_index ) + 1;
      if ( i < state.productsGGG.length ){
        lv = lv + Number( state.productsGGG[i][property_name] );
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:lv, property_name:property_name, product_index:i});
      }
    }
  }

  //---------- в группе изменилась высота - пройти по всем членам группы и установить новую высоту
  if ( isGroup && property_name == "[hv]" ){
    i = Number(product_index)+1;
    while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
      commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:property_value, property_name:property_name, product_index:i});
      i ++;
    }
  }

  //---------- в подгруппе изменилась высота - пройти по всем членам группы и установить новую высоту
  if ( isSubGroup && property_name == "[hv]" ){
    let group_index = GetGroupIndex( state.productsGGG,product_index );
    i = Number(group_index);
    while ( i < state.productsGGG.length && ( state.productsGGG[i].isGroup || state.productsGGG[i].isSubGroup ) ){
      commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:property_value, property_name:property_name, product_index:i});
      i ++;
    }
  }

  //---------- в подгруппе изменилась ширина - пройти по всем членам группы и собрать по ним ширину
  if ( isSubGroup && property_name == "[lv]" ){
    let group_index = GetGroupIndex( state.productsGGG,product_index ), lv = 0;
    i = Number( group_index )+1;
    while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
      let lvsub = Number( state.productsGGG[i][property_name] );
      if ( lvsub ) lv = lv + lvsub;
      i ++;
    }
    commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:lv, property_name:property_name, product_index:group_index});

    /*let group_index = GetGroupIndex( state.productsGGG,product_index );
    i = Number( group_index )+1;
    let lv = Number( state.productsGGG[group_index][property_name] ), lvsub0 = 0;
    while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
      let lvsub = Number( state.productsGGG[i][property_name] );
      if ( !lvsub ) lvsub0 ++; else lv = lv - lvsub;
      i ++;
    }

    if ( lv < 0 ){ //  сумма ширин членов группы больше ширины группы
      if ( lvsub0 ) { // есть пустые ширины, разогнать по ним
        lv = lv / lvsub0;
        i = Number( group_index )+1;
        while ( i < state.productsGGG.length && state.productsGGG[i].isSubGroup ){
          let lvsub = Number(state.productsGGG[i][property_name]);
          if ( !lvsub ) { commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:lv, property_name:property_name, product_index:i}); }
          i ++;
        }
      } else { // нет пустых ширин, изменить соседнюю ширину группы, если ее нет, то первую
        i = Number( product_index )+1;
        if ( i >= state.productsGGG.length || !state.productsGGG[i].isSubGroup ) i = Number( group_index )+1;
        lv = lv + Number( state.productsGGG[i][property_name] );
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:lv, property_name:property_name, product_index:i});
      }
    } else if (lv > 0 ){
      state.productsGGG[group_index].err = true
    }*/

  }


  //---------- в подгруппе изменилось изделие слева - поменять изделие справа в левом изделии
  if ( isSubGroup && property_name == "[constr_left]" ){
    if ( state.productsGGG[product_index].nnpos > 1 ){
      commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:property_value, property_name:"[constr_right]", product_index:Number(product_index)-1});
    }
  }

  //---------- в подгруппе изменилось изделие справа - поменять изделие слева в правом изделии
  if ( isSubGroup && property_name == "[constr_right]" ){
    if ( Number(product_index)+1 < state.productsGGG.length ){
      if ( state.productsGGG[Number(product_index)+1].isSubGroup ){
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:property_value, property_name:"[constr_left]", product_index:Number(product_index)+1});
      }
    }
  }

  //---------- в подгруппе изменилось окружение (для панелей) - поменять окружение в соседних изделиях
  if ( isSubGroup ){
    let env = GetEnviroment( state,product_index ), env2 = "";
    if ( env != "" ){
      if ( state.productsGGG[product_index].nnpos == 1 ){ // первая конструкция
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:"- стена -", property_name:"[constr_left]", product_index:Number(product_index)});
      }
      if ( state.productsGGG[product_index].nnpos > 1 ){ // не первая конструкция
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:env, property_name:"[constr_right]", product_index:Number(product_index)-1});
        env2 = GetEnviroment( state,Number(product_index)-1 );
        if ( env2 != "" ){
          commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:env2, property_name:"[constr_left]", product_index:product_index});
        }
      }
      if ( Number(product_index)+1 < state.productsGGG.length ){ // не последняя конструкция
        if ( state.productsGGG[Number(product_index)+1].isSubGroup ){
          commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:env, property_name:"[constr_left]", product_index:Number(product_index)+1});
          env2 = GetEnviroment( state,Number(product_index)+1 );
          if ( env2 != "" ){
            commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:env2, property_name:"[constr_right]", product_index:Number(product_index)});
          }
          } else {
          commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:"- стена -", property_name:"[constr_right]", product_index:Number(product_index)});
        }
      }
      if ( Number(product_index)+1 == state.productsGGG.length ){ // последняя конструкция
        commit("SET_PRODUCT_PROPERTY_TO_STORE", {property_value:"- стена -", property_name:"[constr_right]", product_index:Number(product_index)});
      }
    }
  }


}

function RefreshGroups( products ){ // обновление цветов и заголовков группы
  let n = 0, nn = 0, clr = 0, group = null, group_constr = "";
   for (let i = 0; i < products.length; i++) {
     if ( products[i]["[constr]"] == "Группа конструкций" || products[i]["[constr]"] == "Группа панелей" ){
       n ++; nn = 0;
       clr = clr != 1 ? 1 : 2;
       products[i].isGroup = true;
       products[i].isSubGroup = false;
       products[i].isEndGroup = false;
       products[i].namepos = n.toString();
       products[i].npos = n;
       products[i].nnpos = "";
       products[i].colcolor = clr;
       products[i].constr_group = { lv:products[i]["[lv]"], hv:products[i]["[hv]"], elem: [], };
       group = products[i].constr_group;
       group_constr = products[i]["[constr]"];
       //console.log("group_constr",group_constr);
      } else if ( products[i]["[constr]"] == "Группа конец" ){
       nn ++;
       products[i].isGroup = false;
       products[i].isSubGroup = false;
       products[i].isEndGroup = true;
       products[i].namepos = "<=" + n + ":" + nn;
       products[i].npos = n;
       products[i].nnpos = nn;
       products[i].colcolor = clr;
       products[i]["[hv]"] = "";
       group = null;
     } else if ( group != null ){
       nn ++;
       products[i].isGroup = false;
       products[i].isSubGroup = true;
       products[i].isEndGroup = false;
       products[i].namepos = "<=" + n + ":" + nn;
       products[i].npos = n;
       products[i].nnpos = nn;
       products[i].colcolor = clr;

       if ( group_constr == "Группа конструкций" ){
        if ( products[i]["[elem_spec]"] != "подгруппа" ){ products[i]["[elem_spec]"] = "подгруппа"; products[i].change = true; }
        //console.log("group_constr",group_constr);
      }
       if ( group_constr == "Группа панелей" ){
        if ( products[i]["[elem_spec]"] != "подгруппа2" ){ products[i]["[elem_spec]"] = "подгруппа2"; products[i].change = true; }
        //console.log("group_constr",group_constr);
      }

       group.elem.push( { lv:products[i]["[lv]"], hv:products[i]["[hv]"] } );
     } else {
       n ++;
       products[i].isGroup = false;
       products[i].isSubGroup = false;
       products[i].isEndGroup = false;
       products[i].namepos = n.toString();
       products[i].npos = n;
       products[i].nnpos = "";
       if ( products[i]["[elem_spec]"] != undefined && products[i]["[elem_spec]"] != "" ){
        products[i]["[elem_spec]"] = ""; products[i].change = true;
      }
      group = null;
     }

   }

   //this.SET_PRODUCT_PROPERTY({property_value, property_name, product_index});

   
}

function CalculateTotal( state /*products*/ ){
  let products = state.productsGGG;
  let catalog = state.catalog;
  let sum_total = 0.0, sum_total_discount = 0.0, sum_discount_sum = 0.0, group_index = -1, sum = 0, group_constr = "";
  for ( let i = 0; i < products.length; i ++ ){

    let val = products[i]['[sum]'];
    if ( !products[i].isGroup && !products[i].isEndGroup ){
      if ( val != undefined && !isNaN( val ) ) {
        sum_total = sum_total + Number( val );
        sum_total_discount = sum_total_discount + get_giscount( val,state.discount );
      }
    }
    if ( products[i].isGroup ){
      if ( group_index != -1 ){
        products[group_index]['[price]'] = sum;
        products[group_index]['[sum]'] = sum;
        group_constr = products[group_index]['[constr]'];
        products[group_index].options['[SPEC]'][0] = { index:0, name:group_constr, num:1, price:sum };
        //console.log("group_constr",group_constr);
        //products[group_index].change = true;
      }
      group_index = i; sum = 0;
    } else if ( products[i].isSubGroup ){
      if ( val != undefined ) sum = sum + Number( val );
      if ( i+1 == products.length ){
        if ( group_index != -1 ){
          products[group_index]['[price]'] = sum;
          products[group_index]['[sum]'] = sum;
          group_constr = products[group_index]['[constr]'];
          products[group_index].options['[SPEC]'][0] = { index:0, name:group_constr, num:1, price:sum };
          //console.log("group_constr",group_constr);
          //products[group_index].change = true;
          group_index = -1, sum = 0;
        }
      }
    } else if ( products[i].isEndGroup ){
      if ( group_index != -1 ){
        products[group_index]['[price]'] = sum;
        products[group_index]['[sum]'] = sum;
        group_constr = products[group_index]['[constr]'];
        products[group_index].options['[SPEC]'][0] = { index:0, name:group_constr, num:1, price:sum };
        //console.log("group_constr",group_constr);
        //products[group_index].change = true;
        group_index = -1, sum = 0;
      }
    }
  }
  for ( let i = 0; i < catalog.length; i ++ ){
    let val = catalog[i]['[sum]'], val2 = catalog[i]['[guillotine]']/*, val3 = catalog[i]['[comment]']*/;
    if ( val != undefined && !isNaN( val ) ){
      if ( /*val2 != undefined &&*/ val2 != "-" ){
        val2 = get_giscount( val,state.discount );
        sum_total = sum_total + Number( val );
        sum_total_discount = sum_total_discount + Number( val2 );
        catalog[i]['[guillotine]'] = Number( val ) - Number( val2 );
      }
    }
  }

  sum_discount_sum = sum_total - sum_total_discount;
  state.account_info.total = toStringNumber( sum_total );
  state.total = toStringNumber( sum_total );
  state.account_info.discount_sum = toStringNumber( sum_discount_sum ); //discount_total_calc( sum_total, state.discount );
  state.discount_sum = toStringNumber( sum_discount_sum ); //state.account_info.discount_sum;
  state.account_info.total_discount = toStringNumber( sum_total_discount ); //account_discount_total_calc( sum_total, state.discount );
  state.total_discount = toStringNumber( sum_total_discount ); //state.account_info.total_discount;
}

function GetEnviroment( state,product_index ){ // название конструкции для окружения
  let options = state.productsGGG[product_index].options;
  if ( options != undefined ){
    let info = options['[INFO]'];
    if ( info != undefined ){
      let env = info['[constr_environment]'];
      if ( env != undefined ){
        return env;
      }
    }
  }
  return "";
}

function LeaveGroup( products,product_index ){
  let group_index = GetGroupIndex( products,product_index );
  if ( group_index == -1 ) return;
  let lv = Number( products[product_index]["[lv]"] );
  let lv_group = Number( products[group_index]["[lv]"] ) - lv;

  //products[product_index].isSubGroup = false;
  products[product_index]["[elem_spec]"] = "";
  products[product_index].change = true;
  products[group_index]["[lv]"] = lv_group;
  products[group_index].change = true;
  //console.trace();
}

function JoinInGroup( products,product_index,direct ){
  let group_index;
  let lv = Number( products[product_index]["[lv]"] ); if ( isNaN( lv ) ) lv = 0;

  //products[product_index]["[elem_spec]"] = "подгруппа";
  //products[product_index].change = true;

  if ( direct == -2 ) group_index = GetGroupIndex( products,product_index-2 );
  if ( direct == -1 ) group_index = GetGroupIndex( products,product_index );
  if ( direct == 1 ) group_index = GetGroupIndexRight( products,product_index );
  if ( group_index != -1 ){
    let lv_group = Number( products[group_index]["[lv]"] ) + lv;
    products[group_index]["[lv]"] = lv_group;
    products[group_index].change = true;
    let hv_group = products[group_index]["[hv]"];
    products[product_index]["[hv]"] = hv_group;
    let group_constr = products[group_index]["[constr]"];
    if ( group_constr == "Группа конструкций" ) products[product_index]["[elem_spec]"] = "подгруппа";
    if ( group_constr == "Группа панелей" ) products[product_index]["[elem_spec]"] = "подгруппа2";
    //console.log("group_constr",group_constr);
    products[product_index].change = true;
  }
}

function goLogin( thisobj ){
  //thisobj.STOP_ANIMATION();
  thisobj.no_save = true;
  thisobj.$router.push("/login");
}

function toNumber( str ){
  if ( typeof( str ) == 'string' ){
    let str2 = str.replaceAll( String.fromCharCode(160),"" );
    if ( !isNaN( str2 ) ) return Number( str2 );
  }
  return 0;
}

function toStringNumber( val ){
  if ( typeof( val ) == 'string' ) val = toNumber( val );
  return String( Intl.NumberFormat().format( val ) );
}

function discount_total_calc( account_total,discount ){
  let rc = 0;
  if ( typeof( account_total ) == 'string' ) account_total = toNumber( account_total );
  if ( typeof( discount ) == 'string' ) discount = toNumber( discount );
  if ( !isNaN( account_total ) && !isNaN( discount ) ){
    rc = account_total / 100 * discount;
  }
  return Intl.NumberFormat().format( Math.round ( rc ) );
}

function account_discount_total_calc( account_total,discount ){
  let rc = 0;
  if ( typeof( account_total ) == 'string' ) account_total = toNumber ( account_total );
  if ( typeof( discount ) == 'string' ) discount = toNumber ( discount );
  if ( !isNaN( account_total ) && !isNaN( discount ) ){
    rc = account_total - account_total / 100 * discount;
  }
  return Intl.NumberFormat().format( Math.round ( rc ) );
}

function get_giscount( val,discount ){
  let rc = 0;
  if ( typeof( val ) == 'string' ) val = toNumber( val );
  if ( typeof( discount ) == 'string' ) discount = toNumber( discount );
  if ( !isNaN( val ) && !isNaN( discount ) ) rc = val - val / 100 * discount;
  return rc;

}

function DateStringToString( str ){
  //console.log( "DateStringToString на входе: ",str );
  let date = "",sep,arr,i;
  while ( typeof str == "string" ){
    if ( str.length != 10 ) break;
    i = str.indexOf("-"); if ( i != -1 ) sep = "-";
    i = str.indexOf("/"); if ( i != -1 ) sep = "/";
    i = str.indexOf("."); if ( i != -1 ) sep = ".";
    if ( sep == "" ) break;
    arr = str.split( sep ); if ( arr.length != 3 ) break;
    if ( arr[0].length == 4 && arr[1].length == 2 && arr[2].length == 2 ){
      date = arr[2] + "." + arr[1] + "." + arr[0];
    } else if ( arr[0].length == 2 && arr[1].length == 2 && arr[2].length == 4 ){
      date = arr[0] + "." + arr[1] + "." + arr[2];
    }
    break;
  }
  //console.log( "DateStringToString на выходе: ",date );
  return date;
}
